import React from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'

import Phone from '../../assets/images/phone.png'
import Feedback from '../../assets/images/feedback.png'
import Dialog from '../../assets/images/dialog.png'
import Survey from '../../assets/images/survey.png'
import ScoreBalls from '../../assets/images/score-balls.png'
import NPS from '../../assets/images/nps.png'
import AiBot from '../../assets/images/robot.png'

export default function Content() {

  const TextBlock = props => {
    const { bottom, left } = props

    return (
      <div className="text-block" style={{ left: left || '1rem', bottom: bottom || '2vw' }}>
        { props.children }
      </div>
    )
  }

  return (
    <Container fluid className="content">
      {/* First row with big picture */}
      <Row className="large-content" style={{ backgroundColor: '#d9e4e1' }}>
        <Col>
          <img src={ Phone } alt="phone" style={{ position: 'absolute', right: '10%', top: '0', maxHeight: "95%" }} />
          <TextBlock>
            <h2>Everbetter.fi</h2>
            <p>Simple solutions to complicated problems.</p>
          </TextBlock>
        </Col>
      </Row>

      {/* Second row with dialog, kartoitus and instant feedback stuff */}
      <Row>

        <Col sm={{ size: 6 }} xs={{ size: 12 }}>
          <Row style={{ backgroundColor: '#E3E3E3' }} className="large-content">
            <Col>
              <img src={ Feedback } alt="feedback" style={{ position: 'absolute', right: '5%', top: '30%', height: '45%', maxHeight: '25vw' }} />
              <TextBlock>
                <h2>Feedback</h2>
                <p>Fully customizable feedback gathering.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>

        <Col sm={{ size: 6 }} xs={{ size: 12 }}>

          <Row>
            <Col xs={{ size: 12 }}>
              <Row style={{ backgroundColor: '#F0F0F0' }} className="inner-content">
                <img src={ Dialog } alt="dialog" style={{ position: 'absolute', right: '5%', top: '10%', height: '70%', maxHeight: '25vw' }} />
                <TextBlock>
                  <h2>Dialog</h2>
                  <p>Add our tools straight into your intra.</p>
                </TextBlock>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={{ size: 12 }}>
              <Row style={{ backgroundColor: '#ABC9D7' }} className="inner-content">
                <img src={ Survey } alt="survey" style={{ position: 'absolute', right: '5%', top: '10%', height: '65%', maxHeight: '23vw' }} />
                <TextBlock>
                  <h2>Survey</h2>
                  <p>Easy way to do fast and effective inqueries.</p>
                </TextBlock>
              </Row>
            </Col>
          </Row>

        </Col>

      </Row>

      {/* Third row with aibot & npsbot */}
      <Row>
        <Col sm={{ size: 6 }} xs={{ size: 12 }}>
          <Row style={{ backgroundColor: '#F0F0F0' }} className="large-content">
            <Col>
              <img src={ AiBot } alt="aibot" style={{ position: 'absolute', right: '5%', top: '30%', height: '45%', maxHeight: '25vw' }} />
              <TextBlock>
                <h2>AI</h2>
                <p>Neural network to assist on decision making process.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
        <Col sm={{ size: 6 }} xs={{ size: 12 }}>
          <Row style={{ backgroundColor: '#d9e4e1' }} className="large-content">
            <Col>
              <img src={ NPS } alt="npsbot" style={{ position: 'absolute', right: '5%', top: '30%', height: '45%', maxHeight: '25vw' }} />
              <TextBlock>
                <h2>NPS</h2>
                <p>Measure how satisfied your customers are.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Fourth row with Qaroitus-portal stuff */}
      <Row>
        
        <Col xs={{ size: 12 }}>
          <Row style={{ backgroundColor: '#C1CACE' }} className="large-content">
            <Col>
              <img src={ ScoreBalls } alt="score" style={{ position: 'absolute', right: '5%', top: '10%', maxHeight: "80%" }} />
              <TextBlock bottom='5vw'>
                <h2>Gather data</h2>
                <p>Turn your data into innovative graphs.</p>
              </TextBlock>
            </Col>
          </Row>
        </Col>

      </Row>

    </Container>
  )
}
