import React from 'react'
import './App.css'

import NavigationBar from './components/navbar/NavigationBar'
import Content from './components/content/Content'
import Footer from './components/footer/Footer'

function App() {
  return (
    <div className="app">
      <NavigationBar />
      <Content />
      <Footer />
    </div>
  )
}

export default App
