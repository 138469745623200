import React from 'react'
import {
  Navbar,
  NavbarBrand
} from 'reactstrap'

import Logo from '../../assets/images/logo.png'

export default function NavigationBar() {
  return (
     <Navbar className="header">
        <NavbarBrand href="/" className="mr-auto">
          <div style={{ backgroundImage: `url(${Logo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} className="header-logo"></div>
        </NavbarBrand>
      </Navbar>
  )
}