import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">

      {/* <Container fluid className="text-center">
        <Row>
          <Col xs={{ size: 6 }}>
            <h4 className="footer-content">EverBetter</h4>
          </Col>

          <Col xs={{ size: 6 }}>
            <h4 className="footer-content">Qibbie</h4>
          </Col>
        </Row>
      </Container> */}

      <div className="footer-copyright text-center py-3">© 2019 Copyright:
        <a target="_blank" rel="noopener noreferrer" href="http://qibbie.com"> Qibbie.com</a>
      </div>

    </footer>
  )
}
